import {
  setupMobileNavigationListener,
  setupLinksListener,
} from "./src/js/commonListeners";

const heroAnimationConfig = [
  {
    element: ".hero__image",
    animation: "hero__image--light",
  },
  {
    element: ".hero__display-text-main",
    animation: "slide-in-10",
  },
  {
    element: ".orange-dot",
    animation: "slide-in-10",
  },
  {
    element: ".hero__display-text-additional",
    animation: "slide-in-100",
  },
];

function animate(config) {
  config.forEach((element) => {
    document.querySelectorAll(element.element).forEach((node) => {
      node.classList.remove(element.animation);
    });
  });
}

document.onreadystatechange = () => {
  setupLinksListener();
  setupMobileNavigationListener();
  animate(heroAnimationConfig);
};
