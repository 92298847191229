export const setupMobileNavigationListener = function () {
  const mobileMenuButton = document.getElementById("nav-mobile-button");
  const navLinks = document.querySelectorAll(".nav__link");
  navLinks.forEach((el) => {
    el.addEventListener("click", (e) => {
      if (mobileMenuButton.checked) {
        mobileMenuButton.checked = false;
      }
    });
  });
};

export const setupLinksListener = function () {
  const navLinks = document.querySelectorAll("a");
  navLinks.forEach((el) => {
    el.addEventListener("click", (e) => {
      const href = e.target.attributes.href.nodeValue;
      if (href.includes("#")) {
        e.preventDefault();
        if (href === "#") {
          document.body.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
          const id = e.target.attributes.href.nodeValue.slice(1);
          const target = document.getElementById(id);
          target.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    });
  });
};
